<template>
  <div class="fixed-window" v-outBody>
    <slot></slot>
  </div>
</template>

<script setup>
const vOutBody = {
  mounted (el) {
    document.body.appendChild(el)
  }
}
</script>
