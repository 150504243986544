<template>
  <div class="mls-footer">
    <a href="https://beian.miit.gov.cn" target="_blank">
      <i class="record" data-v-1e3171f8></i> 粤ICP备2021125101号
    </a>
    <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44060502002720" target="_blank">
      <i class="record"></i> 粤公网安备44060502002720号
    </a>
    <a href="javascript:;"
      @click="ElMessage({ type: 'info', showClose: true, duration: 60000, message: 'wenfygeek@126.com' })">
      <svg t="1647089870983" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
        p-id="2235" width="20" height="20">
        <path
          d="M928 160H96c-17.7 0-32 14.3-32 32v640c0 17.7 14.3 32 32 32h832c17.7 0 32-14.3 32-32V192c0-17.7-14.3-32-32-32z m-40 110.8V792H136V270.8l-27.6-21.5 39.3-50.5 42.8 33.3h643.1l42.8-33.3 39.3 50.5-27.7 21.5z"
          p-id="2236" fill="#0075c4" />
        <path
          d="M833.6 232L512 482 190.4 232l-42.8-33.3-39.3 50.5 27.6 21.5 341.6 265.6c20.2 15.7 48.5 15.7 68.7 0L888 270.8l27.6-21.5-39.3-50.5-42.7 33.2z"
          p-id="2237" fill="#0075c4" />
      </svg>
      电子邮箱:wenfygeek@126.com
    </a>
    <p style="font-size: .7em;">总计浏览量:{{ pageViews }}</p>
  </div>
</template>

<script setup>
import { ElMessage } from 'element-plus'
import axios from 'axios'
import { ref, onBeforeMount } from '@vue/runtime-core'
const pageViews = ref(0)
onBeforeMount(async () => {
  const { result } = await axios.get('https://api.mulaosi.cn/getviews/www.mulaosi.cn')
  pageViews.value = result
})

</script>
<style>
.issusTextarea {
  width: 40%;
  height: 30%;
  position: fixed;
  top: 50%;
  left: 50%;
  border: 1px solid var(--grey);
  outline: none;
  transform: translate(-50%, -50%);
  resize: none;
}
</style>
<style lang='scss' scoped>
@import "@/scss/root.scss";

a {
  color: black;
  text-decoration: none;
  font-size: 14px;
  font-family: $font;
  display: flex;
  justify-content: center;
  align-items: center;

  &::after {
    content: "|";
    margin: 0 1rem;
    font-size: 0.1rem;
  }

  &:last-child::after {
    content: "";
    margin: 0;
  }
}

.record {
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-top: -5px;
  vertical-align: middle;
  background: url("../assets/beian.png");
  background-size: cover;
}

.mls-footer {
  display: flex;
  background: white;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 5rem;
  margin: 0 auto;
  text-align: center;

  @media screen and(max-width:"800px") {
    & {
      width: 100%;
      margin: 0 auto;
      flex-flow: column;
      align-items: flex-start;
      position: relative;
    }

    a {
      margin: 5px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10%;
      font-size: 0.5rem;

      &::after {
        content: "";
      }
    }
  }
}

.bilibili {
  &::before {
    content: "";
    width: 20px;
    height: 20px;
    display: inline-block;
    background: url("../assets/bilibili.png");
    background-size: cover;
    margin-top: -5px;
    margin-right: 5px;
    vertical-align: middle;
  }
}

.npm-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
