<template>
  <div class="user-main">
    <!-- @click="loginFlag = true" -->
    <div class="userAvatar-default" v-if="userAvatar === null" @click="loginFlag = true">
      <svg t="1646546442694" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
        p-id="1383" width="38" height="38" style="vertical-align: bottom;">
        <path
          d="M512 74.666667C270.933333 74.666667 74.666667 270.933333 74.666667 512S270.933333 949.333333 512 949.333333 949.333333 753.066667 949.333333 512 753.066667 74.666667 512 74.666667zM288 810.666667c0-123.733333 100.266667-224 224-224S736 686.933333 736 810.666667c-61.866667 46.933333-140.8 74.666667-224 74.666666s-162.133333-27.733333-224-74.666666z m128-384c0-53.333333 42.666667-96 96-96s96 42.666667 96 96-42.666667 96-96 96-96-42.666667-96-96z m377.6 328.533333c-19.2-96-85.333333-174.933333-174.933333-211.2 32-29.866667 51.2-70.4 51.2-117.333333 0-87.466667-72.533333-160-160-160s-160 72.533333-160 160c0 46.933333 19.2 87.466667 51.2 117.333333-89.6 36.266667-155.733333 115.2-174.933334 211.2-55.466667-66.133333-91.733333-149.333333-91.733333-243.2 0-204.8 168.533333-373.333333 373.333333-373.333333S885.333333 307.2 885.333333 512c0 93.866667-34.133333 177.066667-91.733333 243.2z"
          p-id="1384" />
      </svg>
    </div>
    <el-popover title="退出" trigger="hover">
      <template #reference>
        <i v-if="userAvatar === ''" class="userAvatar-default">
          <svg t="1646546442694" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
            p-id="1383" width="38" height="38" style="vertical-align: bottom;">
            <path
              d="M512 74.666667C270.933333 74.666667 74.666667 270.933333 74.666667 512S270.933333 949.333333 512 949.333333 949.333333 753.066667 949.333333 512 753.066667 74.666667 512 74.666667zM288 810.666667c0-123.733333 100.266667-224 224-224S736 686.933333 736 810.666667c-61.866667 46.933333-140.8 74.666667-224 74.666666s-162.133333-27.733333-224-74.666666z m128-384c0-53.333333 42.666667-96 96-96s96 42.666667 96 96-42.666667 96-96 96-96-42.666667-96-96z m377.6 328.533333c-19.2-96-85.333333-174.933333-174.933333-211.2 32-29.866667 51.2-70.4 51.2-117.333333 0-87.466667-72.533333-160-160-160s-160 72.533333-160 160c0 46.933333 19.2 87.466667 51.2 117.333333-89.6 36.266667-155.733333 115.2-174.933334 211.2-55.466667-66.133333-91.733333-149.333333-91.733333-243.2 0-204.8 168.533333-373.333333 373.333333-373.333333S885.333333 307.2 885.333333 512c0 93.866667-34.133333 177.066667-91.733333 243.2z"
              p-id="1384" />
          </svg>
        </i>

        <img v-else :src="userAvatar" class="userAvatar" @mouseover="logoutFlag = true"
          @mouseleave="logoutFlag = false" />
      </template>
      <el-button type="danger" @click="logout">确认退出</el-button>
    </el-popover>
    <InBody>
      <!-- 登录模块 -->
      <mlsLoginMain v-model="loginFlag" v-if="registerFlag === false" />
      <!-- 注册模块 -->
      <mlsRegister v-if="registerFlag" v-model="loginFlag" />
    </InBody>
  </div>
</template>

<script setup>
import mlsLoginMain from './mlsLoginMain'
import mlsRegister from './mlsRegister'
import { ref, onBeforeMount, computed, watch } from '@vue/runtime-core'
import { useMainStore } from '@/pinia/main'
import { useRegister } from '@/pinia/register'
import { ElNotification, ElPopover, ElButton } from 'element-plus'
import InBody from '@/components/inBody.vue'
import axios from 'axios'
const store = useMainStore()
const registerStore = useRegister()
const loginFlag = ref(false)
const userAvatar = ref(null)
const logoutFlag = ref(false)
const registerFlag = ref(computed(() => { return registerStore.registerShow }))
// 检测是否登录
onBeforeMount(async () => {
  const isLogin = store.getLoginStatus()
  if (isLogin) {
    const { data } = await axios.get('/user/userinfo')
    const { avatar } = data
    store.userInfo = data
    userAvatar.value = avatar
  }
})

// 检测是否登录
watch(loginFlag, async (loginFlag) => {
  if (!loginFlag) {
    const { data } = await axios.get('/user/userinfo')
    const { avatar } = data
    store.userInfo = data
    userAvatar.value = avatar
  }
})
function logout () {
  store.setCookie('SESSDATA', '', -1)
  userAvatar.value = null
  return ElNotification({ type: 'success', title: '退出', message: '退出成功', duration: 3000, showClose: true })
}

</script>

<style lang='scss' scoped>
.user-main {
  width: 38px;
  height: 38px;
  position: relative;
}

.userAvatar-default {
  cursor: pointer;
}

.userAvatar {
  width: inherit;
  border-radius: 50%;
  cursor: pointer;
}

.userAvatarlogin {
  position: absolute;
  top: 0;
  right: 0;
  transition: 0.5s;

  &:hover {
    top: 50%;
    right: 50%;
    transform: scale(2);
  }
}
</style>
