<template>
  <div class="mls-search">
    <div class="search-main">
      <input type="text" class="search-input" v-model="keywords" @focus="resultOpen = true" v-blur />
      <i class="clear-icon" @click="keywords = ''">
        <svg
          t="1646396767682"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="2669"
          width="16"
          height="16"
        >
          <path
            d="M457.638905 512.451278 75.857688 894.232494c-14.981203 14.981203-15.108093 39.529295-0.045025 54.591339 15.167444 15.167444 39.503712 15.042601 54.591339-0.045025l381.781216-381.781216 381.78224 381.781216c14.981203 14.981203 39.529295 15.108093 54.591339 0.045025 15.167444-15.167444 15.042601-39.503712-0.045025-54.591339L566.732555 512.451278 948.513772 130.669038c14.981203-14.981203 15.107069-39.529295 0.045025-54.591339-15.168468-15.167444-39.504736-15.042601-54.591339 0.045025L512.186242 457.904964 130.404002 76.122725c-14.981203-14.981203-39.529295-15.107069-54.591339-0.045025-15.167444 15.168468-15.042601 39.504736 0.045025 54.591339L457.638905 512.451278z"
            p-id="2670"
          />
        </svg>
      </i>
    </div>

    <div class="search-result" v-if="resultOpen&array.length!==0">
      <ul>
        <li v-for="item of array" :key="item.eesayId">
          <a
            :href="'//blog.mulaosi.cn/#/'+item.router"
            target="_blank"
            @click="resultOpen = false"
          >{{item.title}}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from '@vue/runtime-core'
import { useSearchStore } from '@/pinia/search'
const store = useSearchStore()
const keywords = ref()
const array = ref([])
watch(keywords, (keywords) => {
  store.searchResult(keywords).then((val) => {
    array.value = [].concat(val)
  })
})

const resultOpen = ref(false)
const vBlur = {
  mounted (el, bind, vnode) {

  }
}
</script>

<style lang='scss' scoped>
a {
  font-size: 0.8rem;
  text-decoration: none;
  color: black;
}
.search-main {
  display: flex;
  min-height: 1rem;
  line-height: 1rem;

  padding: 0.5rem 0;
  background: #f1f2f3;
  border-radius: 10px;
  position: relative;
  width: 100%;
  z-index: 10;
}
.mls-search {
  width: 100%;
  border: 1px solid #e3e5e7;
  display: flex;
  align-items: center;
  border-radius: 10px;
  position: relative;
  z-index: 10;
  .search-input {
    background: transparent;
    margin-left: 1rem;
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
  }
  .clear-icon {
    cursor: pointer;
    position: absolute;
    right: 0.5rem;
  }
}
.search-result {
  border-radius: 10px;
  width: 100%;
  top: 0;
  padding-top: 2rem;
  min-height: 2rem;
  position: absolute;
  background: white;
  border: 1px solid #e3e5e7;
  ul {
    margin: 0;
    border-top: none;
    padding: 0;
    list-style: none;
    li {
      margin: 1rem 0;
      padding-left: 1rem;
    }
  }
}
</style>
