import { defineStore } from 'pinia'
import axios from 'axios'
export const useSearchStore = defineStore('search', {
  state () {
    return {

    }
  },
  actions: {
    async searchResult (keywords) {
      // 创建空数组,用于储存搜索结果
      const array = []
      const regex = new RegExp(keywords, 'ig')
      // 获取所有文章
      if (keywords !== '') {
        const { data } = await axios.get('/eesayall')
        for (const item of data) {
          // 匹配keywords 是否还有
          const result = item.title.match(regex)
          if (result !== null) {
            array.push(item)
          }
        }
      }

      return array
    }
  }

})
