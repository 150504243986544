import axios from 'axios'
import { defineStore } from 'pinia'
export const useMainStore = defineStore('main', {
  state () {
    return {
      session: null,
      userInfo: {},
      docArray: [
        { title: 'Vue2.x', url: 'https://cn.vuejs.org' },
        { title: 'Vue3.x', url: 'https://v3.cn.vuejs.org' },
        { title: 'Pinia', url: 'https://pinia.vuejs.org' },
        { title: 'Axios', url: 'https://axios-http.com/zh/' },
        { title: 'animate.css', url: 'https://animate.style/' },
        { title: 'Element Plus', url: 'https://element-plus.gitee.io/zh-CN/' }
      ]
    }
  },
  actions: {
    getCookie (cookieName) {
      const cookieArray = document.cookie.split(';') ? document.cookie.split(';') : document.cookie
      if (document.cookie.split(';').length > 1) {
        for (const item of cookieArray) {
          const key = item.split('=')[0].trim()
          const value = item.split('=')[1].trim()
          if (key === cookieName) { return { key, value } }
        }
      }
      return null
    },
    getUserInfo () {
      // const sessionid= this.getCookie('SESSDATA')
      axios.get('/api/user/')
    },
    getLoginStatus () {
      return !!this.getCookie('SESSDATA')
    },
    setCookie (key, value, expires) {
      document.cookie = `${key}=${value};expires=${expires}`
    }
  }
})
