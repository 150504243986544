<template>
  <transition>
    <div class="user-register">
      <!-- 关闭窗口 -->
      <div class="register-close">
        <i class="clear-icon" style="cursor: pointer;" @click="register.showRegister(false)">
          <svg t="1646396767682" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
            p-id="2669" width="20" height="20">
            <path
              d="M457.638905 512.451278 75.857688 894.232494c-14.981203 14.981203-15.108093 39.529295-0.045025 54.591339 15.167444 15.167444 39.503712 15.042601 54.591339-0.045025l381.781216-381.781216 381.78224 381.781216c14.981203 14.981203 39.529295 15.108093 54.591339 0.045025 15.167444-15.167444 15.042601-39.503712-0.045025-54.591339L566.732555 512.451278 948.513772 130.669038c14.981203-14.981203 15.107069-39.529295 0.045025-54.591339-15.168468-15.167444-39.504736-15.042601-54.591339 0.045025L512.186242 457.904964 130.404002 76.122725c-14.981203-14.981203-39.529295-15.107069-54.591339-0.045025-15.167444 15.168468-15.042601 39.504736 0.045025 54.591339L457.638905 512.451278z"
              p-id="2670" fill="white" />
          </svg>
        </i>
      </div>

      <!-- title 注册标题 -->
      <h1 style="text-align:center;">注册账号</h1>
      <div class="register-form">
        <el-input class="input-default" type="text" placeholder="请输入用户名" v-model="user.username"
          @blur="registerUsername"></el-input>
        <el-input class="input-default" type="password" placeholder="请输入密码,用户名至少由六位字符组成" v-model="user.password">
        </el-input>
        <el-input type="password" placeholder="请再输入一次密码" v-model="user.againpassword"></el-input>
        <p style="margin:0;">&nbsp;{{ againFlag }}</p>
        <div class="mailcode">
          <el-input class="input-default" type="text" placeholder="请输入绑定的邮箱" v-model="user.mail" @blur="registerMail">
          </el-input>
          <el-button style="width:30%;margin-left:10px;" :disabled="buttonDisabled" @click="sendMailcode($event)">
            {{ mailButton }}</el-button>
        </div>
        <el-input class="input-default" type="number" placeholder="请输入邮箱验证码" v-model="user.mailcode"></el-input>
        <el-button style="width:100%" :disabled="submitDisabled" @click="registerAccount">注册账号</el-button>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { useRegister } from '@/pinia/register'
import { ElButton, ElInput, ElMessage, ElLoading } from 'element-plus'
import { onMounted, reactive, ref, toRefs, watch } from '@vue/runtime-core'

import axios from 'axios'
const register = useRegister()
const againFlag = ref('')
const mailButton = ref('发送验证码')
const buttonDisabled = ref(false)
const submitDisabled = ref(false)
const user = reactive({
  username: null,
  password: null,
  againpassword: null,
  mail: null,
  mailcode: null
})
// 验证两次密码是否一致
watch(() => { return toRefs(user) }, (user) => {
  const password = user.password.value
  const againpassword = user.againpassword.value
  if (password !== againpassword && againpassword !== null) {
    againFlag.value = '两次密码不一致'
    submitDisabled.value = true
  } else {
    againFlag.value = ' '
    submitDisabled.value = false
  }
})

onMounted(() => {
  const loading = ElLoading.service({ target: document.querySelector('.user-register'), fullscreen: false })
  setTimeout(() => {
    loading.close()
  }, 1000)
})
// 验证用户名
function registerUsername () {
  if (/^[0-9]+/gi.test(user.username)) {
    return ElMessage({ type: 'warning', message: '用户名开头不能用数字', showClose: true })
  } else if (user.username.length < 6) {
    return ElMessage({ type: 'warning', message: '用户名至少由六位字符组成', showClose: true })
  }
}
// 验证邮箱
function registerMail () {
  if (/@/gi.test(user.mail) === false && user.mail !== null && user.mail !== '') {
    return ElMessage({ type: 'warning', message: '邮箱格式出错', showClose: true })
  }
}

// 发送邮箱验证码
function sendMailcode () {
  if (user.mail === null || user.mail === '') {
    return ElMessage({ type: 'warning', message: '请输入绑定的邮箱', showClose: true })
  } else {
    mailButton.value = 60
    let timer = null
    clearInterval(timer)
    buttonDisabled.value = true
    timer = setInterval(() => {
      mailButton.value -= 1
      if (mailButton.value < 0 || isNaN(mailButton.value)) {
        mailButton.value = '发送验证码'
        buttonDisabled.value = false
        clearInterval(timer)
      }
    }, 1000)
    axios.post('/user/sendcaptcha', { username: user.username, password: user.password, mail: user.mail }).then(({ data }) => {
      return ElMessage({ type: 'success', message: `邮件已发送至${user.mail}`, showClose: true })
    })
  }
}
function registerAccount () {
  axios.post('/user/register', { account: user.username, password: user.password, email: user.mail, captcha: user.mailcode }).then((result) => {
    if (result.Error) {
      return ElMessage({ type: 'error', message: result.Error, showClose: true })
    } else if (result.msg) {
      register.$state.registerShow = false
      return ElMessage({ type: 'success', message: result.msg, showClose: true })
    }
  })
}
</script>

<style lang='scss' scoped>
@import "@/scss/root.scss";

.user-register {
  border-radius: 0.5rem;
  width: 600px;
  height: 500px;
  position: fixed;
  top: 50%;
  left: 50%;
  // background: rgba($color: #fff, $alpha: 1);
  background: url("@/assets/pexels-thaïs-prieurblanc-9921356.jpg") no-repeat;
  background-size: cover;
  transform: translate(-50%, -50%);
  color: white;
}

.register-close {
  text-align: right;
  margin: 0.5rem 0.5rem 0 0;
}

.register-form {
  width: 60%;
  margin: 0 auto;

  .input-default {
    margin-bottom: 1.5rem;
  }

  .mailcode {
    display: flex;
  }
}

.v-enter-active {
  transform-origin: 0 0;
  animation: scale 0.5s ease-out;
}

.v-leave-active {
  transform-origin: 0 0;
  animation: scale 0.5s reverse ease-out;
}

@keyframes scale {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>
