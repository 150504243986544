<template>
  <div class="loading" v-load>
    <div class="load">
      MuLaoSi
      <p style="font-size:2rem;display:flex;justify-content:center;align-items:center;">
        <el-icon color="white" :size="32" class="is-loading">
          <loading />
        </el-icon>正在加载中...
      </p>
    </div>
  </div>
</template>

<script setup>
import { ElIcon } from 'element-plus'
import { Loading } from '@element-plus/icons-vue'
const vLoad = {
  beforeMount: (el) => {
    window.onload = () => {
      setTimeout(() => {
        el.style.opacity = 0
        setTimeout(() => {
          el.style.display = 'none'
        }, 1000)
      }, 1000)
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/scss/loading.scss";
@import "@/scss/root.scss";
.loading {
  font-family: $font;
  z-index: 999;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $black;
  transition: opacity 1s;
  .load {
    font-size: 10rem;
    font-weight: bold;
    background-clip: text;
    color: transparent;
    background-image: linear-gradient(315deg, $purple, $skyblue, $primary);

    @media screen and (max-width: "700px") {
      & {
        font-size: 8rem;
      }
    }
    @media screen and (max-width: "550px") {
      & {
        font-size: 5rem;
      }
    }
    @media screen and (max-width: "350px") {
      & {
        font-size: 5rem;
        transform-origin: 35% 35%;
        transform: rotate(-90deg);
      }
    }
  }
}
</style>
