import { defineStore } from 'pinia'

export const useRegister = defineStore('register', {
  state () {
    return {
      registerShow: false
    }
  },
  actions: {
    showRegister (flag) {
      this.registerShow = flag
    }
  }
})
