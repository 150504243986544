<template>
  <div class="mls-header-link">
    <!-- 开发文档 -->
    <div class="link-doc">
      <mlsLink href="javascript:;" class="link-item">开发文档</mlsLink>
      <ul class="doc-list">
        <li class="doc-item" v-for="(item, index) of docArray" :key="index">
          <mls-link :href="item.url" target="_blank">{{ item.title }}</mls-link>
        </li>
      </ul>
    </div>
    <!-- 博客文章 -->
    <div class="link-blog">
      <mls-link href="//v2.blog.mulaosi.cn">博客文章</mls-link>
    </div>
    <!-- 更新日志 -->
    <el-popover :title="updateText.version" :width="255">
      <span v-if="updateText.date">发布时间:{{ updateText.date }}</span>
      <span v-else>正在加载中...</span>
      <ul class="log-add" v-if="updateText.add" style="color:var(--green)">
        <li v-for="(item, index) of updateText.add" :key="index">{{ item }}</li>
      </ul>
      <ul class="log-warn" v-if="updateText.warn" style="color:var(--yellow)">
        <li v-for="(item, index) of updateText.warn" :key="index">{{ item }}</li>
      </ul>
      <ul class="log-del" v-if="updateText.del" style="color:var(--red)">
        <li v-for="(item, index) of updateText.del" :key="index">{{ item }}</li>
      </ul>
      <template #reference>
        <div class="link-log">
          <mls-link href="//doc.mulaosi.cn" @mouseenter="updatelog">更新日志</mls-link>
        </div>
      </template>
    </el-popover>
    <div class="link-login">
      <mlsLogin />
    </div>
    <mlsMenu class="link-menu" />
  </div>
</template>

<script setup>
import mlsLink from '@/components/mlsLink.vue'
import mlsLogin from '@/components/login/mlsLogin'
import mlsMenu from '@/components/mlsMenu.vue'
import { useMainStore } from '@/pinia/main'
import { ElPopover } from 'element-plus'
import axios from 'axios'
import { ref } from '@vue/runtime-core'
const store = useMainStore()
const docArray = store.$state.docArray

// 获取更新日志
const updateText = ref('')
async function updatelog () {
  if (updateText.value === '') {
    const result = await axios.get('https://api.mulaosi.cn/getlastupdate')
    updateText.value = result.data
  }
}
</script>

<style lang='scss' scoped>
.mls-header-link {
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media screen and(max-width:'870px') {
    & {
      justify-content: end;
    }
  }
}

@media screen and(max-width:'870px') {
  .link-menu {
    margin: 0 1rem;
    display: flex !important;
  }

  .link-login {
    display: none;
  }
}

.link-login {
  width: 38px;
  z-index: 20;
}

.link-doc,
.link-blog,
.link-log {
  position: relative;
  margin: 0 10px;

  @media screen and (max-width: "1266px") {
    & {
      font-size: 0.9rem;
    }
  }

  @media screen and(max-width:"870px") {
    & {
      display: none;
    }
  }
}

.doc-list {
  visibility: hidden;
  border: 2px solid var(--grey);
  border-radius: 5px;
  position: absolute;
  left: -50%;
  list-style: none;
  background: white;
  opacity: 0;
  padding: 0.5rem 0;
  transform: translateY(-10px);
  transition: 0.5s;
  line-height: 1.5rem;
  z-index: 20;

  &:hover {
    border: 2px solid black;
  }

  .doc-item {
    text-align: center;
    padding: 0 1rem;

    a {
      white-space: nowrap;
    }
  }
}

.link-doc:hover {
  .doc-list {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
}

.log-add,
.log-warn,
.log-del {
  margin: 0;
  padding: 0;
  list-style: none;

  span {
    width: 2rem;
  }
}
</style>
