<template>
  <div class="mls-card-group" style="min-height:53vh">
    <div class="card-item" v-for="item of array" :key="item.eesayId">
      <mlsCard v-bind:item="item" />
    </div>
  </div>
</template>

<script setup>
import mlsCard from './mlsCard'
import axios from 'axios'
import { reactive } from '@vue/runtime-core'

const array = reactive([])
axios.get('/article/allArticle').then(({ data }) => {
  for (const item of data) {
    array.unshift(item)
  }
})
</script>

<style lang='scss' scoped>
.mls-card-group {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .card-item {
    width: 24.7%;
    margin: 5px 0;
    height: 300px;
  }

  @media screen and (max-width: "1600px") {
    .card-item {
      width: 33%;
    }
  }

  @media screen and (max-width: "1266px") {
    .card-item {
      width: 49.5%;
    }
  }

  @media screen and (max-width: "800px") {
    .card-item {
      width: 99%;
    }
  }
}
</style>
