<template>
  <div class="home-hero">
    <h1>在这里会发布我的杂七杂八的知识分享</h1>
    <h2>或许这些杂七杂八的文章对您也有所帮助</h2>
    <h2>正在紧锣密鼓地开发中...</h2>
  </div>
</template>

<script setup>
</script>

<style lang='scss' scoped>
@import "@/scss/root.scss";
.home-hero {
  margin: 5rem 0;
  width: 100%;
  @media screen and (max-width: "600px") {
    h1,
    h2 {
      font-size: 1.25rem;
    }
  }
  @media screen and (max-width: "400px") {
    h1,
    h2 {
      font-size: 1rem;
    }
  }
  h1 {
    background: $background-1;
    background-clip: text;
    color: transparent;
    text-align: center;
    user-select: none;
  }
  h2 {
    @extend h1;
  }
  .mls-link {
    font-family: $font;
    &:hover::after,
    &:hover::before {
      width: 30%;
    }
  }
}
</style>
