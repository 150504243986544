<template>
  <div class="header-menu">
    <i class="menu-icon" @click="showAside = true">
      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" viewBox="0 0 448 512" class="icon"
        width="24" height="24">
        <path fill="currentColor"
          d="M436 124H12c-6.627 0-12-5.373-12-12V80c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12z" />
      </svg>
    </i>
    <transition name="aside">
      <aside class="aside" v-if="showAside">
        <i class="close-icon" @click="showAside = false">
          <svg t="1647090556336" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
            p-id="4472" width="32" height="32">
            <path fill="white"
              d="M504.224 470.288l207.84-207.84a16 16 0 0 1 22.608 0l11.328 11.328a16 16 0 0 1 0 22.624l-207.84 207.824 207.84 207.84a16 16 0 0 1 0 22.608l-11.328 11.328a16 16 0 0 1-22.624 0l-207.824-207.84-207.84 207.84a16 16 0 0 1-22.608 0l-11.328-11.328a16 16 0 0 1 0-22.624l207.84-207.824-207.84-207.84a16 16 0 0 1 0-22.608l11.328-11.328a16 16 0 0 1 22.624 0l207.824 207.84z"
              p-id="4473" />
          </svg>
        </i>
        <mlsLogin class="aside-login" />
        <div class="aside-doc">
          <a href="javascript:;" @click="docListFlag = !docListFlag">开发文档</a>
          <transition name="doc-list">
            <ul class="doc-list" v-show="docListFlag">
              <li class="doc-item" v-for="(item, index) of docArray" :key="index">
                <a :href="item.url" target="_blank">{{ item.title }}</a>
              </li>
            </ul>
          </transition>
        </div>
        <a href="http://blog.mulaosi.cn" target="_blank">博客文章</a>
        <el-popover :title="updateText.version" :width="255">
          <span v-if="updateText.date">发布时间:{{ updateText.date }}</span>
          <span v-else>正在加载中...</span>

          <ul class="log-add" v-if="updateText.add" style="color:var(--green)">
            <li v-for="(item, index) of updateText.add" :key="index">{{ item }}</li>
          </ul>
          <ul class="log-warn" v-if="updateText.warn" style="color:var(--yellow)">
            <li v-for="(item, index) of updateText.warn" :key="index">{{ item }}</li>
          </ul>
          <ul class="log-del" v-if="updateText.del" style="color:var(--red)">
            <li v-for="(item, index) of updateText.del" :key="index">{{ item }}</li>
          </ul>
          <template #reference>
            <div class="link-log">
              <a href="//doc.mulaosi.cn" @mouseenter="updatelog">更新日志</a>
            </div>
          </template>
        </el-popover>
      </aside>
    </transition>
  </div>
</template>

<script setup>
import { ref, reactive } from '@vue/runtime-core'
import mlsLogin from '@/components/login/mlsLogin.vue'
import { useMainStore } from '@/pinia/main'
import { ElPopover } from 'element-plus'
import axios from 'axios'
const updateText = ref('')
async function updatelog () {
  if (updateText.value === '') {
    const result = await axios.get('/getlastupdate')
    updateText.value = result.data
  }
}
const showAside = ref(false)
const docArray = reactive([])
const docListFlag = ref(false)
const store = useMainStore()
docArray.push(...store.docArray)
</script>

<style lang='scss' scoped>
@import "@/scss/root.scss";

.aside-enter-active {
  animation: scrollLeft 0.5s;
}

.aside-leave-active {
  animation: scrollLeft 0.5s reverse;
}

.doc-list-enter-active {
  animation: scrollDown 0.5s;
}

.doc-list-leave-active {
  animation: scrollDown 0.5s reverse;
}

@keyframes scrollDown {
  from {
    opacity: 0;
    transform: translateY(-1rem);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes scrollLeft {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

a {
  font-family: $font;
  color: white;
  text-decoration: none;
  margin: 1rem 0;
}

.header-menu {
  align-items: center;
  display: none;
  cursor: pointer;

  .aside {
    cursor: auto;
    text-align: center;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 30;
    background: rgba($color: #333, $alpha: 1);
    display: flex;
    flex-direction: column;

    .close-icon {
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 10px;
      display: block;
      width: 32px;
      height: 32px;
    }

    .aside-login {
      width: 48px;
      height: 48px;
      margin-top: 1rem;
      margin-left: 1rem;
    }

    .aside-doc {
      text-align: center;
      overflow: hidden;

      .doc-list {
        margin: 0;
        padding: 0;
        list-style: none;
        margin-top: 1rem;
      }
    }
  }
}

.log-add,
.log-warn,
.log-del {
  margin: 0;
  padding: 0;
  list-style: none;

  span {
    width: 2rem;
  }
}
</style>
