<template>
  <transition>
    <div class="mls-login-main" v-if="props.modelValue">
      <!-- 关闭窗口 -->
      <div class="login-close">
        <i class="clear-icon" @click="emits('update:modelValue', false)">
          <svg t="1646396767682" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
            p-id="2669" width="20" height="20">
            <path
              d="M457.638905 512.451278 75.857688 894.232494c-14.981203 14.981203-15.108093 39.529295-0.045025 54.591339 15.167444 15.167444 39.503712 15.042601 54.591339-0.045025l381.781216-381.781216 381.78224 381.781216c14.981203 14.981203 39.529295 15.108093 54.591339 0.045025 15.167444-15.167444 15.042601-39.503712-0.045025-54.591339L566.732555 512.451278 948.513772 130.669038c14.981203-14.981203 15.107069-39.529295 0.045025-54.591339-15.168468-15.167444-39.504736-15.042601-54.591339 0.045025L512.186242 457.904964 130.404002 76.122725c-14.981203-14.981203-39.529295-15.107069-54.591339-0.045025-15.167444 15.168468-15.042601 39.504736 0.045025 54.591339L457.638905 512.451278z"
              p-id="2670" fill="white" />
          </svg>
        </i>
      </div>
      <!-- title 登录标题 -->
      <h1 style="text-align:center;margin-top:3rem;">密码登录</h1>
      <!-- form表单 -->
      <div class="login-form">
        <!-- 用户名 -->
        <div class="form-username">
          <span>用户名:</span>
          <el-input placeholder="请输入用户名" class="form-input" v-model="user.username" @keyup.enter="login" />
        </div>
        <!-- 密码 -->
        <div class="form-password">
          <span>密码:</span>
          <el-input placeholder="请输入密码" class="form-input" type="password" v-model="user.password"
            @keyup.enter="login" />
        </div>
        <!-- 按钮 -->
        <el-button-group class="button-group">
          <el-button auto-insert-space class="button-default" @click="registerOpen">注册</el-button>
          <el-button auto-insert-space type="primary" class="button-default" @click="login">登录</el-button>
        </el-button-group>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { reactive, defineProps, defineEmits } from '@vue/runtime-core'
import { ElInput, ElButton, ElButtonGroup, ElNotification } from 'element-plus'
import { useRegister } from '@/pinia/register'
import axios from 'axios'
const props = defineProps({ modelValue: Boolean })
const emits = defineEmits(['update:modelValue'])
const register = useRegister()
const user = reactive({
  username: '',
  password: ''
})
// 登录按钮
function login () {
  user.username = user.username.trim()
  user.password = user.password.trim()
  if (user.username.length === 0) {
    return ElNotification({ showClose: true, message: '用户名不能为空', title: '错误', type: 'error', duration: 3000 })
  }
  if (user.password.length === 0) {
    return ElNotification({ showClose: true, message: '密码不能为空', title: '错误', type: 'error', duration: 3000 })
  } else if (user.password.length > 0 && user.password.length < 6) {
    return ElNotification({ showClose: true, message: '密码不能小于6位', title: '错误', type: 'error', duration: 3000 })
  }

  // axios post请求 account password 参数
  axios.post('/user/login', { account: user.username, password: user.password }).then((response) => {
    const { code, msg } = response
    if (code === 0) {
      return ElNotification({ showClose: true, message: msg, title: '错误', type: 'error', duration: 3000 })
    } else {
      user.username = user.password = ''
      emits('update:modelValue', false)
      return ElNotification({ showClose: true, message: msg, title: '成功', type: 'success', duration: 3000 })
    }
  })
}

function registerOpen () {
  register.showRegister(true)
}

</script>

<style lang='scss' scoped>
.v-enter-active {
  transform-origin: 0 0;
  animation: scale 0.5s ease-out;
}

.v-leave-active {
  transform-origin: 0 0;
  animation: scale 0.5s reverse ease-out;
}

@keyframes scale {
  from {
    opacity: 0;
    transform: scale(0) translate(-50%, -50%);
  }

  to {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%);
  }
}

.login-form {
  margin: 1rem auto;
}

.clear-icon {
  cursor: pointer;
}

.login-close {
  text-align: right;
  margin: 0.5rem 0.5rem 0 0;
}

.mls-login-main {
  border-radius: 0.5rem;
  width: 600px;
  height: 400px;
  position: fixed;
  top: 50%;
  left: 50%;
  // background: rgba($color: #fff, $alpha: 1);
  background: url("@/assets/pexels-thaïs-prieurblanc-9921356.jpg") no-repeat;
  background-size: cover;
  transform: translate(-50%, -50%);
  color: white;
}

.form-username,
.form-password {
  width: 60%;
  margin: 1rem auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    text-align: right;
    width: 5rem;
    margin-right: 10px;
  }
}

.form-input {
  flex: 1;
}

.button-group {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin: 1rem auto;

  .button-default {
    width: 30%;
    height: 2.5rem;
  }
}

.el-button-group .el-button--primary:last-child {
  border-left-color: transparent;
}
</style>
