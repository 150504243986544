<template>
  <a href="https://www.mulaosi.cn" class="mls-logo">MuLaoSi</a>
</template>

<script>
export default {

}
</script>

<style scoped lang='scss'>
@import "../scss/root.scss";

.mls-logo {
  justify-content: center;
  font-size: 3rem;
  background-image: linear-gradient(315deg, $purple, $skyblue, $primary);
  background-clip: text;
  color: transparent;
}
</style>
