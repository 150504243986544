import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import axios from 'axios'
import '@/scss/root.scss'
import 'element-plus/dist/index.css'
// axios.defaults.baseURL = 'https://api.mulaosi.cn/'
// axios.defaults.baseURL = 'http://localhost:7777/'
// axios.defaults.baseURL = 'http://beta.mulaosi.cn/api/v1/'
axios.defaults.baseURL = '/api/v1'
axios.defaults.headers.post = { 'Content-Type': 'application/x-www-form-urlencoded' }
axios.interceptors.response.use((response) => {
  return response.data
})
const app = createApp(App)
app.use(router)
app.use(createPinia())
app.mount('#app')
