<template>
  <div class="mls-card" v-if="item.status === 1 ? true : false">
    <div class="card-picture">
      <img v-if="item.cover" :src="item.cover" />
      <span v-else style="font-size:2rem;">无图片</span>
      <a class="card-router" :href="item.url" target="_blank">跳转文章</a>
    </div>
    <div class="card-text">
      <p class="card-title">
        <mls-link :href="item.url" target="_blank">{{ item.title }}</mls-link>
      </p>
      <div class="card-link">
        <mlsLink class="card-link-item" :href="item.referencelink" target="_blank">引用链接: {{ item.referencelinktitle }}
        </mlsLink>
      </div>
      <div class="tag">
        <ul class="tag-list">
          <li class="tag-item" v-for="(t, index) of tag" :key="index">{{ t }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, onBeforeMount, reactive } from '@vue/runtime-core'
import mlsLink from '@/components/mlsLink'

const props = defineProps(['item'])
const tag = reactive([])
onBeforeMount(() => {
  if (props.item.tag) {
    tag.unshift(...JSON.parse(props.item.tag))
  }
})
</script>

<style lang='scss' scoped>
@import "@/scss/root.scss";

.mls-card {
  font-family: $font;
  border-radius: 0.44rem;
  width: 100%;
  height: 300px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 20px rgba($color: #000000, $alpha: 0.2);

  .card-picture {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: inherit;
    overflow: hidden;
    height: 70%;
    position: relative;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
      transform-origin: center 0;
      object-fit: cover;
      transition: 0.5s;
    }
  }

  .card-text {
    width: 100%;
    text-align: center;
  }

  .card-link-item {
    font-size: 0.5rem;
    width: 100%;
    display: block;
    white-space: nowrap;
    word-break: keep-all;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .card-title {
    white-space: nowrap;
    width: 100%;
    word-break: keep-all;
    overflow: hidden;
    padding-bottom: 2px;
    text-overflow: ellipsis;
  }
}

.card-picture {
  position: relative;

  &:hover {
    .card-router {
      background: rgba($color: #000, $alpha: 0.5);
      opacity: 1;
      visibility: visible;
    }

    img {
      transform: scale(1.2);
    }

    .card-router {
      transform: scale(1) translate(-50%, -50%);
    }
  }

  .card-router {
    transform-origin: 0 0;
    top: 50%;
    left: 50%;
    position: absolute;
    text-decoration: none;
    padding: 1rem;
    border-radius: 2rem;
    opacity: 0;
    visibility: hidden;
    transform: scale(10) translate(-50%, -50%);
    transition: 0.3s;
    color: white;
    box-shadow: 0 0 10px rgba($color: #000, $alpha: 0.3),
      0 0 30px rgba($color: #000, $alpha: 0.5);
    font-weight: bold;
  }
}

.tag-list {
  padding: 0;
  margin-top: 0.5rem;
  list-style: none;
  justify-content: space-around;
  display: flex;

  .tag-item {
    padding: 2px 5px;
    border: 1px solid black;
    border-radius: 1rem;
    font-size: 0.5rem;
  }

  @media screen and(max-width:'300px') {
    margin-top: -1rem;
    opacity: 0;
  }
}
</style>
